/* You can add global styles to this file, and also import other style files */

@import 'styles-variables';

// keep commented out until move completely to material styling and only using bootstrap for grid/layout
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/utilities"; // add css utilities

@import 'bootstrap/scss/bootstrap';
@import "@angular/material/prebuilt-themes/indigo-pink.css";


@import 'styles-reset';

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// @import '@angular/material/prebuilt-themes/purple-green.css';

///
// To get mat-form-fields to take up 100% of the bootstrap grid
///

mat-form-field {
  width: 100%;
  min-width: auto;
  .mat-form-field-infix {
    width: auto;
  }
}

.mat-table-full-width {
  width: 100%;
}

th.mat-sort-header-sorted {
  color: black;
}

///
// To get mat list to highlight selected (sub menu)
///
.mat-list-active {
  background: blue;
}

///
// to remove extra spacing below the matfield
///
.mat-form-field-no-error {
  margin-bottom: -1.25em
}

///
// To fill space in mat-toolbars (to right justify)
///
.tlm-mat-spacer {
  flex: 1 1 auto;
}

///
// To get mat divider vertical to show
///
.tlm-mat-vertical-divider {
  height: 100%;
  margin-left: 18px !important;
  margin-right: 18px !important;
}

///
// ngx-gallery material override
///

.mat-sidenav-container, .mat-sidenav-content, .mat-tab-body-content {
  transform: none !important;
}

///
// ngx-gallery arrow shadow effect
///
.ngx-gallery-arrow {
  text-shadow: 0px 0px 4px #333333
}


///
// profile type/status styles
///

// profile type: threat red, bolo orange
// profile status: threat red, bolo active orange, bolo inactive grey
$threat-color: #be0006;
$threat-background: #FFC7CE;
$bolo-color: #f88f04;
$bolo-background: #f8ecba;
$bolo-inactive-color: #646464;
$bolo-inactive-background: #CECECE;

@mixin threat-foreground {
  color: $threat-color;
}
@mixin threat-background {
  background: $threat-background;
}
@mixin bolo-foreground {
  color: $bolo-color;
}
@mixin bolo-background {
  background: $bolo-background;
}
@mixin bolo-inactive-foreground {
  color: $bolo-inactive-color;
}
@mixin bolo-inactive-background {
  background: $bolo-inactive-background;
}

///
// tlm-profile-type-threat
///
.tlm-profile-type-threat {
  @include threat-foreground;
  @include threat-background;
}
.tlm-profile-type-threat-foreground {
  @include threat-foreground;
}
.tlm-profile-type-threat-background {
  @include threat-background;
}

///
// tlm-profile-type-bolo
///
.tlm-profile-type-bolo {
  @include bolo-foreground;
  @include bolo-background;
}
.tlm-profile-type-bolo-foreground {
  @include bolo-foreground;
}
.tlm-profile-type-bolo-background {
  @include bolo-background;
}

///
// tlm-profile-type-bolo-incomplete
///
.tlm-profile-type-bolo-incomplete {
  @include bolo-foreground;
  @include bolo-background;
}
.tlm-profile-type-bolo-incomplete-foreground {
  @include bolo-foreground;
}
.tlm-profile-type-bolo-incomplete-background {
  @include bolo-background;
}

///
// profile type buttons
///
.tlm-profile-type-bolo-outline-button {
  color: $bolo-color;
  border: 2px solid $bolo-color;
  background-color: white;
}
.tlm-profile-type-bolo-outline-button:hover {
  color: $bolo-color;
  background-color: $bolo-background;
}
.tlm-profile-type-threat-outline-button {
  color: $threat-color;
  border: 2px solid $threat-color;
  background-color: white;
}
.tlm-profile-type-threat-outline-button:hover {
  color: $threat-color;
  background-color: $threat-background;
}

///
// tlm-profile-status-threat
///
.tlm-profile-status-threat {
  @include threat-foreground;
  @include threat-background;
}
.tlm-profile-status-threat-foreground {
  @include threat-foreground;
}
.tlm-profile-status-threat-background {
  @include threat-background;
}

///
// tlm-profile-status-bolo-active
///
.tlm-profile-status-bolo-active {
  @include bolo-foreground;
  @include bolo-background;
}
.tlm-profile-status-bolo-active-foreground {
  @include bolo-foreground;
}
.tlm-profile-status-bolo-active-background {
  @include bolo-background;
}

///
// tlm-profile-status-bolo-inactive
///
.tlm-profile-status-bolo-inactive {
  @include bolo-inactive-foreground;
  @include bolo-inactive-background;
}
.tlm-profile-status-bolo-inactive-foreground {
  @include bolo-inactive-foreground;
}
.tlm-profile-status-bolo-inactive-background {
  @include bolo-inactive-background;
}

@mixin add-profile-modal-header {
  font-size: 1.75rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;  
  margin-bottom: 1rem; 
  border-bottom: 2px solid;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.tlm-add-profile-modal-header {
  @include add-profile-modal-header;
  margin-bottom: 0rem;
  color:  #0c4569;
  border-bottom-color: #0c4569;
}

.tlm-add-profile-threat-modal-header {
  @include add-profile-modal-header;
  color: $threat-color;
  border-bottom-color: $threat-color;
}

.tlm-add-profile-bolo-modal-header {
  @include add-profile-modal-header;
  color: $bolo-color;
  border-bottom-color: $bolo-color;
}

.tlm-fa-15x {
  font-size: 1.5em;
}

.tlm-fa-25x {
  font-size: 2.5em;
}


.rich-text-editor-error .ql-container {
  border:1px solid red !important
}

///
// Spark-core settings
///
@import '../../node_modules/@rocketcentral/rocket-design-system-styles/web/scss/color';
//@import '../../node_modules/@sparkdesignsystem/spark-core/settings/_colors.scss';
@import '../../node_modules/@rocketcentral/rocket-design-system-styles/web/scss/settings';

// everything appeared bold in chrome
$font-family-header: 'Roboto', sans-serif;

$font-family-body: 'Times New Roman', Times, serif;
$black: #222222;
$grey-medium: #aaaaaa;

// missing spark colors?
$red-medium: red;
$blue-dark: blue;
$blue-medium: blue;


.background {
  background: linear-gradient(#44235d, #0c4569);
}

.header-button {
  min-width: 80px;
  max-width: 80px;
}

.btn-header-link {
  font-weight: 400;
  color: white;
  background-color: transparent;
}

.btn-header-link:hover {
  color: white;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-header-link:focus,
.btn-header-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-header-link:disabled,
.btn-header-link.disabled {
  color: $grey-medium;
}

%header-shared {
  font-weight: 500;
  color: $blue-dark;
  font-family: $font-family-header;
}

.tlm-header {
  @extend %header-shared;
  font-size: 16pt;
}

.bolo-header{
  @extend %header-shared;
  font-size: 23pt;
  text-align: center;
}

.tlm-sub-header {
  @extend %header-shared;
  font-size: 14pt;
}

// .tlm-sub-menu {
//   background-color: transparent;
//   background-clip: border-box;
//   border: 1px solid $blue-medium;
//   border-radius: 0.25rem;
//   color: $blue-medium;
// }

// %tlm-sub-menu-item-shared {
//   display: block;
//   padding: 0.5rem 1rem;
// }

// .tlm-sub-menu-item {
//   @extend %tlm-sub-menu-item-shared;
//   color: $blue-medium;
// }

// .tlm-sub-menu-item:hover {
//   text-decoration: none;
//   cursor: pointer;
// }

// .tlm-sub-menu-item:focus,
// .tlm-sub-menu-item.focus {
//   text-decoration: none;
//   box-shadow: none;
// }

// .tlm-sub-menu-item:disabled,
// .tlm-sub-menu-item.disabled {
//   color: $grey-medium;
// }

// .tlm-sub-menu-item-active {
//   @extend %tlm-sub-menu-item-shared;
//   background-color: $blue-medium;
//   color: white;
// }

// .tlm-sub-menu-item-active:hover {
//   text-decoration: none;
//   cursor: pointer;
// }

// .tlm-sub-menu-item-active:focus,
// .tlm-sub-menu-item-active.focus {
//   text-decoration: none;
//   box-shadow: none;
// }

// .tlm-sub-menu-item-active:disabled,
// .tlm-sub-menu-item-active.disabled {
//   color: $grey-medium;
// }

%body-shared {
  font-size: 10pt;
  color: $black;
  font-family: $font-family-body;
}

.tlm-section-title {
  @extend %body-shared;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.tlm-label {
  @extend %body-shared;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.tlm-value {
  @extend %body-shared;
  font-weight: normal;
}

.tlm-link {
  @extend %body-shared;
  color: #007bff;
}

.tlm-link-remove {
  @extend %body-shared;
  color: red;
}

.tlm-action {
  color: $red-medium;
}

.tlm-action-disabled {
  color: $grey-medium;
}

%tlm-form-list-item-simple-close-shared {
  position: absolute;
  top: 23%;
  left: 100%;
}

%tlm-form-list-item-simple-close-ddl-shared {
  @extend %tlm-form-list-item-simple-close-shared;
  left: 108%;
}

.tlm-form-list-item-simple-close-disabled {
  @extend %tlm-form-list-item-simple-close-shared;
  color: $grey-medium;
}

.tlm-form-list-item-simple-close {
  @extend %tlm-form-list-item-simple-close-shared;
  cursor: pointer;
  color: $red-medium;
}

.tlm-form-list-item-simple-close-ddl-disabled {
  @extend %tlm-form-list-item-simple-close-ddl-shared;
  color: $grey-medium;
}

.tlm-form-list-item-simple-close-ddl {
  @extend %tlm-form-list-item-simple-close-ddl-shared;
  cursor: pointer;
  color: $red-medium;
}

%tlm-form-list-item-card-close-shared {
  position: absolute;
  top: 2%;
  left: 94.4%;
}

.tlm-form-list-item-card-close-disabled {
  @extend %tlm-form-list-item-card-close-shared;
  color: $grey-medium;
}

.tlm-form-list-item-card-close {
  @extend %tlm-form-list-item-card-close-shared;
  cursor: pointer;
  color: $red-medium;
}

%tlm-form-list-item-handle-up-down-shared {
  position: absolute;
  left: 94%;
}

%tlm-form-list-item-handle-up-shared {
  @extend %tlm-form-list-item-handle-up-down-shared;
  top: 35%;
}

%tlm-form-list-item-handle-down-shared {
  @extend %tlm-form-list-item-handle-up-down-shared;
  bottom: 35%;
}

.tlm-form-list-item-card-handle-up {
  @extend %tlm-form-list-item-handle-up-shared;
  cursor: pointer;
  color: $blue-medium;
}

.tlm-form-list-item-card-handle-up-disabled {
  @extend %tlm-form-list-item-handle-up-shared;
  color: $grey-medium;
}

.tlm-form-list-item-card-handle-down {
  @extend %tlm-form-list-item-handle-down-shared;
  cursor: pointer;
  color: $blue-medium;
}

.tlm-form-list-item-card-handle-down-disabled {
  @extend %tlm-form-list-item-handle-down-shared;
  color: $grey-medium;
}

.tlm-form-list-item-card-content {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tlm-no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.tlm-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tlm-top-buffer {
  margin-top: 20px;
}
.cdk-overlay-pane.add-threat-modal-panel{
  border: thick solid $threat-color;
  border-radius: 8px;
  position: relative!important;

}
.cdk-overlay-pane.add-bolo-modal-panel{
  border: thick solid $bolo-color;
  border-radius: 8px;
  position: relative!important;
}
.cdk-overlay-pane.add-profile-selection-modal-panel{
  position: relative!important;
}

.button-cancel.mat-mdc-button {
  position: absolute;
  top: 10px;
  right: 0px;
  padding-right: 5px;
  padding-top: 0px;
  line-height: 14px;
  min-width: auto;
}

.mat-mdc-card.mdc-card {
  padding: 16px;
}
.container {
  max-width: 1140px;
}

#genericHeader {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;;
}
.mdc-button {
  letter-spacing: normal;
  justify-content: left !important;
}

.col-1.my-auto button {
  padding: 0% 0% 0% 0%;
  margin: 0%;
}

#addPersonHeader {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;;
}
.tlm-sub-menu button {
 width: 0%;
}

.tlm-profile-section , #socialMedia{
  padding: 0%;
}
#leftPaneOfDetails {
  padding-right: 3%;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  padding: 24px;
}

.mat-mdc-button>.mat-icon {
  font-size: 2.125rem !important;
  width: 2.125rem !important;
  height: 2.125rem !important;
  margin-right: 0px;
}

.text-left.mat-mdc-button {
  justify-content: flex-start;
  text-align: left !important;
}

.mat-mdc-form-field {
  margin-top: 5px;
}