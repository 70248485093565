// https://medium.com/@tomastrajan/how-to-build-responsive-layouts-with-bootstrap-4-and-angular-6-cfbb108d797b
// https://www.amadousall.com/the-good-parts-of-bootstrap-4-you-are-missing-in-your-angular-material-projects/

* {
  &:active,
  :focus {
    outline: none !important;
  }
}

label {
  margin-bottom: 0;
}

// keep commented out until complete switch to material
// a:not(.mat-button):not(.mat-raised-button):not(.mat-fab):not(.mat-mini-fab):not([mat-list-item]) {
//   color: #3f51b5;
// }
